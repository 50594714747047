import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import NewsletterInput from '../components/NewsletterInput';
import RankingList from '../components/RankingList';

import '../style/ranking.sass';

const Ranking = ({ location }) => {
  const [gender, setGender] = useState('men');
  const [page, setPage] = useState(1);
  const [men, setMen] = useState([]);
  const [women, setWomen] = useState([]);
  const [mensList, setMensList] = useState([]);
  const [womensList, setWomensList] = useState([]);

  // TODO: Fetch from the ATP Top 10 instead of the internal API
  useEffect(() => {
    async function fetchPlayers() {
      const data = await fetch(
        'https://api.shuttlesport.subsite.ca/atp_grandSlamWinners/'
      );
      const players = await data.json(); // Already sorted
      setMen(players);
      // TODO: Set women's list
      // TODO: I don't like this, but it'll do for now
      setTimeout(() => {
        const hash = location.hash ? location.hash.replace('#') : '';
        if (hash) {
          const criteriaContainer = document.querySelector('#criteria');
          criteriaContainer.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
    }
    fetchPlayers();
  }, []);

  // On "page" switch
  useEffect(() => {
    const players = gender === 'men' ? men : women;
    if (!players.length) {
      return;
    }
    const startingRank = (page - 1) * 20;
    // const slice = players.slice(startingRank, startingRank + 20);
    const slice = players.slice(0, 10);
    if (gender === 'men') {
      setMensList(slice);
    } else {
      setWomensList(slice);
    }
  }, [men, women, gender, page]);

  const shouldShowList = () => {
    if (gender === 'men') {
      return men.length > 0 && mensList.length > 0;
    } else {
      return women.length > 0 && womensList.length > 0;
    }
  };

  // const scrollIfNeeded = () => {
  //   if (!alreadyScrolled) {
  //     // Scroll down if URL hash is specified
  //     const hash = location.hash ? location.hash.replace('#') : '';
  //     if (hash) {
  //       console.log('calling scroll');
  //       const criteriaContainer = document.querySelector('#criteria');
  //       criteriaContainer.scrollIntoView();
  //       setAlreadyScrolled(true);
  //     }
  //   }
  //   return true;
  // }

  return (
    <Layout>
      <div className='container'>
        <div className='section'>
          <h2>GOAT 100</h2>
          <h3 className='subheader'>top one hundred player list</h3>
          <div className='button-section'>
            <button
              className={`button button-${gender === 'men' ? 'green' : 'gray'}`}
              onClick={() => setGender('men')}
            >
              Men
            </button>
            <button
              className={`button button-${
                gender === 'women' ? 'green' : 'gray'
              }`}
              onClick={() => setGender('women')}
            >
              Women
            </button>
          </div>
          {shouldShowList() && (
            <RankingList
              playerList={gender === 'men' ? mensList : womensList}
            />
          )}
          {/* {shouldShowList() && (
            <div className='ranking-container'>
              <div className='page-selector'>
                <div
                  className={`circle ${page !== 1 ? 'filled' : ''}`}
                  onClick={() => {
                    setPage(1);
                  }}
                />
                <div
                  className={`circle ${page !== 2 ? 'filled' : ''}`}
                  onClick={() => {
                    setPage(2);
                  }}
                />
                <div
                  className={`circle ${page !== 3 ? 'filled' : ''}`}
                  onClick={() => {
                    setPage(3);
                  }}
                />
                <div
                  className={`circle ${page !== 4 ? 'filled' : ''}`}
                  onClick={() => {
                    setPage(4);
                  }}
                />
                <div
                  className={`circle ${page !== 5 ? 'filled' : ''}`}
                  onClick={() => {
                    setPage(5);
                  }}
                />
              </div>
              <RankingList />
            </div>
          )} */}
        </div>
      </div>
      <div id='criteria' className='container blue-bg'>
        <div className='section'>
          <h2>the criteria</h2>
          <h3 className='subheader'>
            4 categories researched & analyzed to determine the GOAT 100
          </h3>
          <div className='categories'>
            <div className='category-row'>
              <div className="category-label a-label">
                <div className="label">On-court <br /> success</div>
              </div>
              <div className='description'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. At
                lectus urna duis convallis convallis tellus id. Lacinia at quis
                risus sed vulputate odio ut enim blandit. Morbi leo urna
                molestie at elementum eu. Ultrices sagittis orci a scelerisque
                purus semper eget. Penatibus et magnis dis parturient montes
                nascetur ridiculus mus.
              </div>
            </div>
            <div className='category-row'>
              <div className="category-label b-label">
                <div className='label'>
                  Longevity & <br />
                  consistency
                </div>
              </div>
              <div className='description'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. At
                lectus urna duis convallis convallis tellus id. Lacinia at quis
                risus sed vulputate odio ut enim blandit. Morbi leo urna
                molestie at elementum eu. Ultrices sagittis orci a scelerisque
                purus semper eget. Penatibus et magnis dis parturient montes
                nascetur ridiculus mus.
              </div>
            </div>
            <div className='category-row'>
              <div className="category-label c-label">
                <div className='label'>
                  Off-court <br />
                  recognition
                </div>
              </div>
              <div className='description'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. At
                lectus urna duis convallis convallis tellus id. Lacinia at quis
                risus sed vulputate odio ut enim blandit. Morbi leo urna
                molestie at elementum eu. Ultrices sagittis orci a scelerisque
                purus semper eget. Penatibus et magnis dis parturient montes
                nascetur ridiculus mus.
              </div>
            </div>
            <div className='category-row'>
              <div className='category-label d-label'>
                <div className='label'>
                  Influence
                </div>
              </div>
              <div className='description'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. At
                lectus urna duis convallis convallis tellus id.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container green-bg'>
        <div className='section'>
          <h2>voting</h2>
          <h3 className='subheader'>your opinion matters</h3>
          <div className='inner-section'>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. At
              lectus urna duis convallis convallis tellus id. Lacinia at quis
              risus sed vulputate odio ut enim blandit. Morbi leo urna molestie
              at elementum eu. Ultrices sagittis orci a scelerisque purus semper
              eget. Penatibus et magnis dis parturient montes nascetur ridiculus
              mus.
            </div>
            <h3>sign-up below for TG100 updates</h3>
            <NewsletterInput justifyContent='flex-start' />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Ranking;
