import React, { useState } from 'react';

const NewsletterInput = ({ justifyContent, alignItems }) => {
  const [email, setEmail] = useState();
  const [msg, setMsg] = useState();

  async function subscribe() {
    setMsg('');
    const response = await fetch('/api/subscribe', {
      method: 'POST',
      body: JSON.stringify({ email }),
    });
    try {
      const { message } = await response.json();
      setMsg(message);
    } catch (err) {
      setMsg('Something went wrong - please try again later.')
    }
  }

  return (
    <div>
      <div
        className='newsletter-input'
        style={{
          justifyContent: justifyContent || 'center',
          alignItems: alignItems || 'center',
        }}
      >
        <input
          className='input'
          placeholder='Email address*'
          autoComplete='email'
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className='button button-blue' onClick={subscribe}>
          Subscribe
        </button>
      </div>
      <p>{msg}</p>
    </div>
  );
};

export default NewsletterInput;
