import React, { useEffect } from 'react';

import '../style/custom-style.sass';
import '../style/ranking.sass';

import RacketImg from '../img/players/racket.jpeg';
import PlayerImg from '../img/players/djokovic.jpeg';

const RankingList = ({ playerList }) => {
  return (
    <div className='ranking-list'>
      <div className='header-row'>
        <div>rank</div>
        <div>total points</div>
      </div>
      <div className='rows'>
        {playerList.map((player, index) => {
          const startingRank = 0;
          return (
            <div className='row' key={player.player_id}>
              <div className='info'>
                <div className='rank'>{startingRank + index + 1}</div>
                {/* TODO: Replace these with player headshots + rackets */}
                <img className='icon' src={RacketImg} />
                <img className='icon' src={PlayerImg} />
                <div>
                  <div className='name'>{`${player.name_first} ${player.name_last}`}</div>
                  <div className='racket'>HEAD Speed Pro BLK 2023</div>
                </div>
              </div>
              <div className='points'>{player.CalculatedPoints}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RankingList;

// Old component (uses page)
// const RankingList = () => {
//   return (
//     <div className='ranking-list'>
//       <div className='header-row'>
//         <div>rank</div>
//         <div>total points</div>
//       </div>
//       <div className='rows'>
//         {(gender === 'men' ? mensList : womensList).map((player, index) => {
//           const startingRank = (page - 1) * 20;
//           return (
//             <div className='row' key={player.player_id}>
//               <div className='rank-name'>
//                 <div className='rank'>{startingRank + index + 1}</div>
//                 <div className='name'>{`${player.name_first} ${player.name_last}`}</div>
//               </div>
//               <div>{player.CalculatedPoints}</div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };
